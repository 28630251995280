import { DateTime } from 'luxon'

export const getTicks = (data, dayOne) => {
  if (!data || !data.rawValues) {
    return {}
  }

  const referenceDate = DateTime.fromISO(dayOne)

  // eslint-disable-next-line no-magic-numbers
  const tickHelper = [0, 2, 4, 6, 8, 10, 12]

  const years = tickHelper.map((t) => referenceDate.year + t)

  // first key date should be fixed at zero position on x-axis, and for other key dates calculation should be performed
  const ticks = years.map((year, index) =>
    index === 0
      ? 0
      : Math.abs(referenceDate.diff(DateTime.fromISO(`${year}-01-01`), 'years').years),
  )

  return { years, ticks }
}
