import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DealCashFlowLineChart as DealCashFlowLineChartV1 } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/chart-components/DealCashFlowLineChartV1'
import { DealCashFlowLineChart as DealCashFlowLineChartV2 } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/chart-components/DealCashFlowLineChartV2'
import { TILE_VERSION } from 'components/domains/business-events-and-tasks/decision-paper/tiles/constant'

const DealCashFlowLineChartWrapper = ({ tileId, ...otherProps }) => {
  const { version } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const dealCashflowLineChartTile = useMemo(() => {
    switch (version) {
      case TILE_VERSION.V1:
        return <DealCashFlowLineChartV1 {...otherProps} />
      default:
        return <DealCashFlowLineChartV2 {...otherProps} />
    }
  }, [version, otherProps])

  return dealCashflowLineChartTile
}

DealCashFlowLineChartWrapper.propTypes = {
  tileId: PropTypes.string.isRequired,
}

export default DealCashFlowLineChartWrapper
