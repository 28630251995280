import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DealCashflowChartWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/chart-components/DealCashFlowChartWrapper'
import DealCashFlowLineChartWrapper from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/chart-components/DealCashFlowLineChartWrapper'
import useGetMaturityData from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/useDealCashFlowGetMaturityData'

export const DEAL_CASH_FLOW_VACANCY_CONFIG = [
  {
    type: 'line',
    legend: 'legend.actual',
    legendType: 'plainline',
    color: '#2531D4',
    key: 'value',
  },
]

export const DEAL_CASH_FLOW_FIXED_VACANCY_KPI = 'VR_EOP'

export const DealCashFlowVacancyCard = ({
  isLoading,
  isError,
  calculatedKpis,
  isCard = true,
  metaData,
  showTitle,
  tileId,
}) => {
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'components.deals.cashflow.financial-ratios',
  })
  const [selectedCashflowKpi, setSelectedCashflowKpi] = useState()

  const selectedData = calculatedKpis?.find(
    (data) => data.category === null && data.cashflowKpi.code === DEAL_CASH_FLOW_FIXED_VACANCY_KPI,
  )
  const { maturityData = {} } = useGetMaturityData(selectedData)

  const headerProps = {
    selectedData,
    isLoading,
    isError,
    maturityData,
    setSelectedCashflowKpi,
    selectedCashflowKpi,
    isFixed: true,
    title: t('title.vacancy'),
    subtitlePrefix: t('subtitle.vacancy-rate'),
    showTitle: showTitle,
  }

  return (
    <DealCashflowChartWrapper
      isCard={isCard}
      isLoading={isLoading}
      isError={isError}
      isSelectedData={!!selectedData}
      isMetaData={!!metaData}
      headerProps={headerProps}
    >
      <DealCashFlowLineChartWrapper
        data={selectedData}
        maturityData={maturityData}
        config={DEAL_CASH_FLOW_VACANCY_CONFIG}
        tileId={tileId}
      />
    </DealCashflowChartWrapper>
  )
}

DealCashFlowVacancyCard.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  calculatedKpis: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      cashflowKpi: PropTypes.shape({
        code: PropTypes.string,
      }),
    }),
  ),
  isCard: PropTypes.bool,
  metaData: PropTypes.object,
  showTitle: PropTypes.bool,
  tileId: PropTypes.string.isRequired,
}
