import { IllustrationMessageType } from '@fioneer/ui5-webcomponents-react'
import camelize from 'camelize'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CashFlowScenarioNameTile from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/CashFlowScenarioNameTile'
import EmptyCardContent from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowEmptyCardContent'
import ErrorScreen from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowErrorScreen'
import { DealCashFlowVacancyCard } from 'components/domains/business-events-and-tasks/decision-paper/tiles/cash-flow/shared/DealCashFlowVacancyCard'
import { setSourceRender } from 'redux/slices/decision-paper/tilesOverviewSlice'

const MultipleCashFlowVacancyProjectionTile = ({ tileId, selectedDealIndex, isPdfView }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('decisionPaper', {
    keyPrefix: 'tiles.cash-flow',
  })

  const {
    data: {
      dealsData,
      multipleDealTileMetaData,
      multipleCashflowScenarioPeriodQuartersData,
      multipleCashflowScenarioPeriodYearsData,
      multipleFilteredUsedParameters,
    },
  } = useSelector((state) => state.decisionPaper.tilesOverview.tiles[tileId])

  const { dealUuid: selectedDealUuid, dealDisplayId: selectedDealDisplayId } =
    dealsData?.[selectedDealIndex] ?? {}

  useEffect(() => {
    dispatch(setSourceRender({ tileId, sourceRender: { dealDisplayId: selectedDealDisplayId } }))
  }, [dispatch, selectedDealDisplayId, tileId])

  const {
    usedParameters,
    cashflowScenarioPeriodYearsData,
    cashflowScenarioPeriodQuartersData,
    tileMetaData,
  } = useMemo(
    () => ({
      cashflowScenarioPeriodYearsData: multipleCashflowScenarioPeriodYearsData.find(
        ({ dealUuid }) => dealUuid === selectedDealUuid,
      )?.cashflowScenario,
      cashflowScenarioPeriodQuartersData: multipleCashflowScenarioPeriodQuartersData.find(
        ({ dealUuid }) => dealUuid === selectedDealUuid,
      )?.cashflowScenario,
      usedParameters: multipleFilteredUsedParameters.find(
        ({ dealUuid }) => dealUuid === selectedDealUuid,
      )?.filteredUsedParameters,
      tileMetaData: camelize(
        multipleDealTileMetaData.find(({ dealUuid }) => dealUuid === selectedDealUuid),
      ),
    }),
    [
      multipleCashflowScenarioPeriodQuartersData,
      multipleCashflowScenarioPeriodYearsData,
      multipleDealTileMetaData,
      multipleFilteredUsedParameters,
      selectedDealUuid,
    ],
  )

  const cashflowScenarioPeriodNotFound =
    !usedParameters || !cashflowScenarioPeriodYearsData || !cashflowScenarioPeriodQuartersData

  if (tileMetaData && cashflowScenarioPeriodNotFound) {
    return (
      <ErrorScreen title={t('loading.error.title')} description={t('loading.error.description')} />
    )
  }

  return (
    <>
      <CashFlowScenarioNameTile
        metaData={tileMetaData}
        dealId={selectedDealDisplayId}
        showMessageStrip={false}
        isPdfView={isPdfView}
      />
      {tileMetaData ? (
        <DealCashFlowVacancyCard
          calculatedKpis={cashflowScenarioPeriodYearsData?.calculatedKpis}
          isCard={false}
          metaData={tileMetaData}
          showTitle={false}
          isLoading={false}
          isError={false}
          tileId={tileId}
        />
      ) : (
        <EmptyCardContent
          illustrationName={IllustrationMessageType.UnableToLoad}
          title={t('no-scenario.selected.title')}
          subtitle={t('no-scenario.selected.subtitle')}
        />
      )}
    </>
  )
}

MultipleCashFlowVacancyProjectionTile.propTypes = {
  tileId: PropTypes.string.isRequired,
  isPdfView: PropTypes.bool.isRequired,
  selectedDealIndex: PropTypes.number.isRequired,
}

export default MultipleCashFlowVacancyProjectionTile
