import { Button, ButtonDesign, Dialog } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { getRentalUnitWorkingVersionRowKey } from 'components/domains/properties/rent-roll/working-version/utils/rentRollWorkingVersionMappers'
import LoadingScreen from 'components/ui/screens/LoadingScreen'
import { useMatchOrCreateSegmentsToRentalUnits } from 'hooks/services/properties/rent-roll/working-version/useMatchOrCreateSegmentsToRentalUnits'

/**
 * use CWP-dialog instead | https://fioneer.atlassian.net/browse/CWP-13200
 */
const PropertyRentRollWorkingVersionPageCreateSegmentsButton = ({
  propertyUuids,
  rentalUnitsValues,
  setRentalUnitsValues,
  updateRentalUnit,
  updateRentalUnitsBulk,
}) => {
  const { t } = useTranslation('translation')

  const segmentsButtonIsDisabled = rentalUnitsValues.every(
    (rentalUnit) =>
      !rentalUnit.property_uuid?.value ||
      rentalUnit.segment_uuid.value ||
      !rentalUnit.segment_usage_type_id.value,
  )

  const [isCreatingSegments, setIsCreatingSegments] = useState(false)

  const mapSegmentUuidsAndCloseLoadingDialog = (mappedTableData) => {
    const updatedTableData = mappedTableData.map((rowWithSegmentUuid) =>
      updateRentalUnit(
        rentalUnitsValues,
        getRentalUnitWorkingVersionRowKey(rowWithSegmentUuid.row_number),
        { segment_uuid: rowWithSegmentUuid.segment_uuid },
      ),
    )
    setRentalUnitsValues((rentalUnitValues) =>
      updateRentalUnitsBulk(rentalUnitValues, updatedTableData),
    )
    setIsCreatingSegments(false)
  }

  const reducedTableDataForSegmentCreation = [
    ...rentalUnitsValues.map((rentalUnit) => ({
      row_number: rentalUnit.row_number,
      property_uuid: rentalUnit.property_uuid.value,
      segment_uuid: rentalUnit.segment_uuid.value,
      segment_usage_type_id: rentalUnit.segment_usage_type_id.value,
    })),
  ]

  const { matchOrCreateSegments } = useMatchOrCreateSegmentsToRentalUnits(
    propertyUuids,
    mapSegmentUuidsAndCloseLoadingDialog,
  )

  return (
    <>
      <Button
        id="rent-roll-working-version-create-segments-button"
        disabled={segmentsButtonIsDisabled}
        onClick={() => {
          setIsCreatingSegments(true)
          matchOrCreateSegments(reducedTableDataForSegmentCreation)
        }}
        design={ButtonDesign.Emphasized}
      >
        {t('pages.property.rent-roll.button.create-segments')}
      </Button>
      {createPortal(
        <Dialog open={isCreatingSegments} id="loadingScreenDialog">
          <LoadingScreen
            title={t('app.loading.title')}
            description={t('app.loading.description')}
          />
        </Dialog>,
        document.body,
      )}
    </>
  )
}

PropertyRentRollWorkingVersionPageCreateSegmentsButton.propTypes = {
  propertyUuids: PropTypes.arrayOf(PropTypes.string.isRequired),
  rentalUnitsValues: PropTypes.array.isRequired,
  setRentalUnitsValues: PropTypes.func.isRequired,
  updateRentalUnit: PropTypes.func.isRequired,
  updateRentalUnitsBulk: PropTypes.func.isRequired,
}

export default PropertyRentRollWorkingVersionPageCreateSegmentsButton
