import {
  FlexBox,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Input,
  Label,
  Tab,
  TabContainer,
} from '@fioneer/ui5-webcomponents-react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import getPropertyRentalUnitsTableContentDefinitions from 'components/domains/properties/getPropertyRentRollWorkingVersionReferenceData'
import styles from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableEditDialog.module.css'
import {
  MassEditDefaultComboBox,
  MassEditNumberComboBox,
  MassEditSelect,
  MassEditBusinessPartnerComboBox,
  MassEditBooleanSelect,
  MassEditDatePickerComboBox,
} from 'components/domains/properties/rent-roll/working-version/rental-units-table/PropertyRentalUnitsWorkingVersionTableEditDialogContentFieldTypes'
import useGetAllRentRollWorkingVersionOptions from 'hooks/services/properties/rent-roll/working-version/useGetAllRentRollWorkingVersionOptions'
import { useMassEditDefaultOptions } from 'hooks/services/properties/rent-roll/working-version/useMassEditDefaultOptions'

const getFirstLevelContentKeys = (isMultiProperty) => {
  const multiPropertyContentKeys = isMultiProperty ? ['property_uuid'] : []
  return [
    'rental_unit_name',
    ...multiPropertyContentKeys,
    'tenant_name',
    'occupancy_status_id',
    'segment_uuid',
    'segment_usage_type_id',
    'rental_unit_area',
    'rental_unit_area_uom_id',
    'rent_contracted_year',
    'current_net_rent',
    'rental_unit_currency_id',
    'rent_start_date',
    'lease_start_date',
    'lease_break_date',
    'lease_expiry_date',
  ]
}

const secondLevelContentKeys = [
  'number_of_units',
  'no_lease_expiry',
  'branding_franchise_partner_name',
  'rent_dispute',
  'anonymous_tenant',
  'rent_arrears',
  'comment',
  'auxiliary_column1',
  'auxiliary_column2',
  'auxiliary_column3',
  'auxiliary_column4',
]

const PropertyRentalUnitsWorkingVersionTableEditDialogContent = ({
  handleOnChange,
  massEditChoices,
  rowsToEdit,
  segments,
  isMultiProperty,
  isDialogOpen,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.table',
  })

  const { KEEP_VALUES, CLEAR_VALUES } = useMassEditDefaultOptions()

  const allOptions = useGetAllRentRollWorkingVersionOptions()

  const getSegmentDisplayName = (segment) => {
    if (!isMultiProperty) {
      return segment.name
    }
    const property = allOptions?.properties?.find(({ key }) => key === segment.property_uuid) || ''
    return `${property.selectDisplayValue} - ${segment.name}`
  }

  const getSegmentOptions = () =>
    segments
      ? segments.map((segment) => ({
          key: segment.uuid,
          display_name: getSegmentDisplayName(segment),
        }))
      : []

  const getPropertyOptions = () =>
    allOptions?.properties?.map((property) => ({
      key: property.key,
      display_name: property.selectDisplayValue,
    }))

  const getUsageTypeIdBySegmentUuid = (uuid) =>
    segments.find((segment) => segment.uuid === uuid).usage_type_code

  const contentDefinitions = getPropertyRentalUnitsTableContentDefinitions(t, isMultiProperty)

  const getContentTitleByKey = (key) =>
    contentDefinitions.find((conDef) => conDef.contentKey === key).title

  const originalRowValues = (contentKey) =>
    rowsToEdit ? rowsToEdit.map((row) => row.original[contentKey].value) : []

  const renderSelect = (contentKey, options, handleOnChangeSelect = handleOnChange) => (
    <MassEditSelect
      contentKey={contentKey}
      contentTitle={getContentTitleByKey(contentKey)}
      massEditChoices={massEditChoices}
      options={options}
      handleOnChange={handleOnChangeSelect}
    />
  )

  const renderComboBox = (contentKey, handleComboBoxChange = handleOnChange) => (
    <MassEditDefaultComboBox
      contentKey={contentKey}
      contentTitle={getContentTitleByKey(contentKey)}
      originalValues={originalRowValues(contentKey)}
      massEditChoices={massEditChoices}
      handleOnChange={handleComboBoxChange}
      isMassEditDialogOpen={isDialogOpen}
    />
  )

  const renderNumberCombobox = (
    contentKey,
    handleComboBoxChange = handleOnChange,
    decimalPlaces,
  ) => (
    <MassEditNumberComboBox
      contentKey={contentKey}
      contentTitle={getContentTitleByKey(contentKey)}
      originalValues={originalRowValues(contentKey)}
      massEditChoices={massEditChoices}
      handleOnChange={handleComboBoxChange}
      decimalPlaces={decimalPlaces}
      isMassEditDialogOpen={isDialogOpen}
    />
  )

  const renderBusinessPartnerFields = (contentKeyName, contentKeyId) => (
    <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
      <FlexBox className={styles['flexbox-60']} direction={FlexBoxDirection.Column}>
        <MassEditBusinessPartnerComboBox
          contentKeyName={contentKeyName}
          contentKeyId={contentKeyId}
          contentTitle={getContentTitleByKey(contentKeyName)}
          massEditChoices={massEditChoices}
          handleOnChange={handleOnChange}
          isMassEditDialogOpen={isDialogOpen}
        />
      </FlexBox>
      <FlexBox className={styles['flexbox-30']} direction={FlexBoxDirection.Column}>
        <Label>{getContentTitleByKey(contentKeyId)}</Label>
        <Input className={styles['input']} value={massEditChoices[contentKeyId]} readonly={true} />
      </FlexBox>
    </FlexBox>
  )

  const handleSegmentChoice = ({ choice }) => {
    if (choice !== KEEP_VALUES && choice !== CLEAR_VALUES) {
      const segmentUsageTypeId = getUsageTypeIdBySegmentUuid(choice)
      handleOnChange({
        contentKey: 'segment_usage_type_id',
        choice: segmentUsageTypeId,
      })
    }
    handleOnChange({ contentKey: 'segment_uuid', choice: choice })
  }

  const renderMassEditDialogContent = (contentKey) => {
    switch (contentKey) {
      case 'property_uuid':
        return renderSelect(contentKey, getPropertyOptions())
      case 'occupancy_status_id':
        return renderSelect(contentKey, allOptions.occupancyStatusCodes)
      case 'rental_unit_area_uom_id':
        return renderSelect(contentKey, allOptions.areaMeasureUnitCodes)
      case 'rental_unit_currency_id':
        return renderSelect(contentKey, allOptions.currencyCodes)
      case 'segment_uuid':
        return renderSelect(contentKey, getSegmentOptions(), handleSegmentChoice)
      case 'no_lease_expiry':
      case 'rent_dispute':
      case 'anonymous_tenant':
        return (
          <MassEditBooleanSelect
            contentKey={contentKey}
            contentTitle={getContentTitleByKey(contentKey)}
            massEditChoices={massEditChoices}
            handleOnChange={handleOnChange}
          />
        )
      case 'segment_usage_type_id':
        return renderSelect(contentKey, allOptions.segmentUsageTypeCodes, ({ choice }) => {
          handleOnChange({
            contentKey: 'segment_uuid',
            choice: CLEAR_VALUES,
          })
          handleOnChange({ contentKey, choice })
        })
      case 'tenant_name':
        return renderBusinessPartnerFields(contentKey, 'tenant_id')
      case 'branding_franchise_partner_name':
        return renderBusinessPartnerFields(contentKey, 'branding_franchise_partner_id')
      case 'rental_unit_area':
      case 'rent_contracted_year':
      case 'current_net_rent':
        return renderNumberCombobox(contentKey, handleOnChange, 2)
      case 'number_of_units':
      case 'rent_arrears':
        return renderNumberCombobox(contentKey, handleOnChange, 0)
      case 'rent_start_date':
      case 'lease_start_date':
      case 'lease_break_date':
      case 'lease_expiry_date':
        return (
          <MassEditDatePickerComboBox
            contentKey={contentKey}
            contentTitle={getContentTitleByKey(contentKey)}
            massEditChoices={massEditChoices}
            originalValues={originalRowValues(contentKey)}
            handleOnChange={handleOnChange}
            isMassEditDialogOpen={isDialogOpen}
          />
        )
      default:
        return renderComboBox(contentKey)
    }
  }

  const mapContents = (contentKeys) =>
    contentKeys.map((contentKey) => (
      <FlexBox key={`mass-edit-${contentKey}`} direction={FlexBoxDirection.Column}>
        {renderMassEditDialogContent(contentKey)}
      </FlexBox>
    ))

  return (
    <FlexBox>
      <TabContainer className={styles['tab-container']}>
        <Tab
          selected
          text={
            t('edit-dialog.first-level-columns') +
            ' (' +
            getFirstLevelContentKeys(isMultiProperty).length +
            ')'
          }
        >
          {mapContents(getFirstLevelContentKeys(isMultiProperty))}
        </Tab>
        <Tab
          text={t('edit-dialog.second-level-columns') + ' (' + secondLevelContentKeys.length + ')'}
        >
          {mapContents(secondLevelContentKeys)}
        </Tab>
      </TabContainer>
    </FlexBox>
  )
}

PropertyRentalUnitsWorkingVersionTableEditDialogContent.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  massEditChoices: PropTypes.object.isRequired,
  rowsToEdit: PropTypes.array,
  segments: PropTypes.array,
  isMultiProperty: PropTypes.bool.isRequired,
  isDialogOpen: PropTypes.bool,
}
export default PropertyRentalUnitsWorkingVersionTableEditDialogContent
