import { Text, Title, TitleLevel } from '@fioneer/ui5-webcomponents-react'

import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  ResponsiveContainer,
  Area,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from 'recharts'
import styles from 'components/domains/deals/deal-cashflow/result-charts/chart-components/DealCashflowLineChart.module.css'
import { getCustomXAxisTick } from 'components/domains/deals/deal-cashflow/result-charts/chart-components/getCustomXAxisTick'
import { getTicks } from 'components/domains/deals/deal-cashflow/result-charts/chart-components/getTicks'
import { useNumberFormatter, useShortDateFormatter } from 'hooks/i18n/useI18n'

const STROKE_WIDTH = 1.5
const EQUITY_COLOR = '#A97DD8'
const TOTAL_DEBT_COLOR = '#D3BDEB'
const EQUITY_RATIO_LINE_COLOR = '#2E70B6FF'

export const DealCashflowDebtEquityChart = ({ data, maturityData }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.deals.cashflow.equity-debt',
  })
  const { t: tNoPrefix } = useTranslation()
  const { format: formatDate } = useShortDateFormatter()

  const formatNumber = useNumberFormatter({
    maximumFractionDigits: 0,
    notation: 'compact',
  })

  const formatNumberTooltip = useNumberFormatter({
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    notation: 'compact',
  })

  const getTooltip = ({ payload = [] }) => {
    if (!payload[0]?.payload) {
      return <></>
    }

    const equityValue =
      payload[0].payload.equity || payload[0].payload.equity === 0
        ? `${formatNumberTooltip(payload[0].payload.equity)}`
        : t('empty-value')
    const totalDebtValue =
      payload[0].payload.totalDebt || payload[0].payload.totalDebt === 0
        ? `${formatNumberTooltip(payload[0].payload.totalDebt)}`
        : t('empty-value')
    const equityRatioDebtValue =
      payload[0].payload.kpi || payload[0].payload.kpi === 0
        ? `${formatNumberTooltip(payload[0].payload.kpi)} ${tNoPrefix('unit.percent')}`
        : t('empty-value')

    return (
      <div className={styles.tooltip}>
        <Title className={styles.tooltipTitle} level={TitleLevel.H6}>
          {formatDate(payload[0].payload.endDate)}
        </Title>
        <div className={styles.tooltipRow}>
          <Text className={styles.tooltipTextLabel}>{t('legend.equity')}</Text>
          <Text className={styles.tooltipTextValue}>{equityValue}</Text>
        </div>
        <div className={styles.tooltipRow}>
          <Text className={styles.tooltipTextLabel}>{t('legend.total-debt')}</Text>
          <Text className={styles.tooltipTextValue}>{totalDebtValue}</Text>
        </div>
        <div className={styles.tooltipRow}>
          <Text className={styles.tooltipTextLabel}>{t('legend.equity-ratio-debt')}</Text>
          <Text className={styles.tooltipTextValue}>{equityRatioDebtValue}</Text>
        </div>
      </div>
    )
  }

  if (!data) {
    return <></>
  }

  // first value (dayOne) should not be part of the graph
  const lineGraphValues = data.slice(1)
  const dayOne = lineGraphValues[0].endDate
  const lastDate = lineGraphValues.slice(-1)[0].endDate
  const refDayOne = DateTime.fromISO(dayOne)
  const { years, ticks } = getTicks({ rawValues: data }, dayOne)

  const maturityIndex = data?.findIndex((item) => item.endDate === maturityData?.endDate)

  const dataBeforeMaturity =
    maturityIndex > 0 ? lineGraphValues?.slice(0, maturityIndex) : lineGraphValues
  const dataAfterMaturity = maturityIndex > 0 ? lineGraphValues?.slice(maturityIndex - 1) : []

  return (
    <ResponsiveContainer className={styles.chartContainer} height={'100%'}>
      <ComposedChart width={500} margin={{ bottom: 60 }}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey={(item) =>
            Math.abs(refDayOne.diff(DateTime.fromISO(item.endDate), 'years').years)
          }
          allowDuplicatedCategory={false}
          tickLine={false}
          tick={(payload) =>
            getCustomXAxisTick(payload, years, formatDate, styles.tickStyle, dayOne, lastDate)
          }
          type="number"
          ticks={ticks}
          interval={0}
          domain={[0, Math.abs(refDayOne.diff(DateTime.fromISO(lastDate), 'years').years)]}
        />
        <YAxis
          className={styles.tickStyle}
          tickLine={false}
          axisLine={false}
          tickFormatter={(val) => formatNumber(val)}
          tickCount={7}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          className={styles.tickStyle}
          tickLine={false}
          axisLine={false}
          padding={{ top: 5 }}
          tickFormatter={(val) => formatNumber(val) + tNoPrefix('unit.percent')}
          tickCount={7}
        />

        <Tooltip content={getTooltip} />
        <Legend
          wrapperStyle={{ marginBottom: '-50px' }}
          payload={[
            {
              value: <Text className={styles.legendText}>{t('legend.equity')}</Text>,
              type: 'square',
              color: EQUITY_COLOR,
              payload: {},
            },
            {
              value: <Text className={styles.legendText}>{t('legend.total-debt')}</Text>,
              type: 'square',
              color: TOTAL_DEBT_COLOR,
              payload: {},
            },
            {
              value: <Text className={styles.legendText}>{t('legend.equity-ratio-debt')}</Text>,
              type: 'circle',
              color: EQUITY_RATIO_LINE_COLOR,
              payload: {},
            },
            {
              value: <Text className={styles.legendText}>{t('legend.maturity')}</Text>,
              type: 'plainline',
              color: '#000',
              payload: {},
            },
          ]}
        />

        <Area
          data={dataBeforeMaturity}
          id={'equity-before'}
          type="linear"
          dataKey="equity"
          stackId="1"
          stroke="#fff"
          fill={EQUITY_COLOR}
        />
        <Area
          id={'equity-after'}
          data={dataAfterMaturity}
          type="linear"
          dataKey="equity"
          stackId="1"
          stroke="#fff"
          fill={EQUITY_COLOR}
        />
        <Area
          id={'total-debt-before'}
          data={dataBeforeMaturity}
          type="linear"
          dataKey="sumOfEquityTotalDebt"
          stackId="1"
          stroke={TOTAL_DEBT_COLOR}
          fill={TOTAL_DEBT_COLOR}
        />
        <Area
          id={'total-debt-after'}
          data={dataAfterMaturity}
          type="linear"
          dataKey="sumOfEquityTotalDebt"
          stackId="1"
          stroke={TOTAL_DEBT_COLOR}
          fill={TOTAL_DEBT_COLOR}
        />
        <Line
          type="linear"
          data={dataBeforeMaturity}
          dataKey={'kpi'}
          stroke={EQUITY_RATIO_LINE_COLOR}
          dot={false}
          id={'area-kpi-before'}
          yAxisId={'right'}
          strokeWidth={STROKE_WIDTH}
        />
        <Line
          type="linear"
          data={dataAfterMaturity}
          dataKey={'kpi'}
          stroke={EQUITY_RATIO_LINE_COLOR}
          dot={false}
          id={'area-kpi-after'}
          strokeDasharray="5 5"
          yAxisId={'right'}
          strokeWidth={STROKE_WIDTH}
        />

        {maturityData && (
          <ReferenceLine
            x={Math.abs(refDayOne.diff(DateTime.fromISO(maturityData.endDate), 'years').years)}
            stroke="black"
            strokeWidth={2}
          />
        )}
        <defs>
          <pattern id="striped-bg-pattern" patternUnits="userSpaceOnUse" width="1.5" height="1.5">
            <line x1="0" y="0" x2="1.5" y2="0" stroke="#FFF" strokeWidth="2" />
          </pattern>

          <mask id="striped-bg-mask">
            <rect fill="url(#striped-bg-pattern)" x="0" y="0" width="100%" height="100%" />
          </mask>
        </defs>

        <style>
          {`
            [class*="waultChart"] .legend-item-1 .recharts-surface,
            #equity-after,
            #total-debt-after {
              mask: url('#striped-bg-mask')
            }
        `}
        </style>
      </ComposedChart>
    </ResponsiveContainer>
  )
}

DealCashflowDebtEquityChart.propTypes = {
  maturityData: PropTypes.object,
  data: PropTypes.array,
}
